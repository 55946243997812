import { Controller } from "stimulus"
import JQuery from 'jquery'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('[data-toggle="tooltip"]').forEach(el => {
      JQuery(el).tooltip({html: true, container: el, sanitize: false})
    })
  }
}
