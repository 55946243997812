import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'tab', 'content' ]

  initialize() {
    this.level = '';
  }

  set level(value){
    if (this.levelLoaded(value)) {
      this.selectLevel(value)
    } else {
      this.loadLevel(value)
    }
  }

  focus(event){
    event.preventDefault()
    this.level = event.currentTarget.dataset.level
  }

  levelLoaded(level) {
    return this.contentTargets.find(el => {
      return (el.dataset.level === level && el.querySelector('.lesson-list'))
    })
  }

  loadLevel(level) {
    Rails.ajax({
      url: this.data.get('url') + '/' + level,
      type: "get",
      success: (doc) => {
        const list = doc.querySelector('.lesson-list')
        const lvl = list.dataset.level

        const tab = this.contentTabFor(lvl)

        const spinner = tab.querySelector('div')
        tab.removeChild(spinner);
        tab.appendChild(list)

        this.selectLevel(lvl)
      }
    })
  }

  selectLevel(level) {
    this.tabTargets.forEach(el => {
      el.classList.toggle('selected', el.dataset.level === level)
    })
    this.contentTargets.forEach(el => {
      el.classList.toggle('d-none', el.dataset.level !== level)
    })
  }

  contentTabFor(level) {
    return this.contentTargets.find(el => { return (el.dataset.level === level) })
  }
}
