import { Controller } from "stimulus"
import JQuery from 'jquery'

export default class extends Controller {
  static targets = [
    'input', 'headerCheckbox', 'count', 'modal', 'assignee', 'opener'
  ]

  initialize() {
    if (this.inputTargets.length > 0) {
      this.headerCheckboxTarget.disabled = false
      this.openerTarget.classList.remove('d-none')
    }
  }

  ajaxSuccess(event) {
    const assignees = event.detail[0]

    if (!Array.isArray(assignees)) return; // reassign action

    this.assigneeTarget.innerHTML = ''

    if (assignees.length > 0) {
      assignees.forEach(assignee => {
        const li = document.createElement('option')
        li.textContent = `${assignee.name} / ${assignee.email}`
        li.value = assignee.id
        this.assigneeTarget.appendChild(li)
      })

      this.modalTarget.querySelector('.alert').classList.add('d-none')
      this.modalTarget.querySelector('button[type=submit]').disabled = false
      this.assigneeTarget.classList.remove('d-none')
    } else {
      this.modalTarget.querySelector('.alert').classList.remove('d-none')
      this.modalTarget.querySelector('button[type=submit]').disabled = true
      this.assigneeTarget.classList.add('d-none')
    }

    JQuery(this.modalTarget).modal('show')
  }

  checkAll(event) {
    this.inputTargets.forEach(input => {
      input.checked = event.currentTarget.checked
    })
    this.countChecked()
  }

  checkOne() {
    this.countChecked()
  }

  countChecked() {
    const count = this.inputTargets.filter(input => input.checked).length
    this.countTarget.innerText = count
    count > 0 ? this.enableOpener() : this.disableOpener()
  }

  disableOpener() {
    const btn = this.openerTarget
    btn.disabled = true
    btn.classList.replace('btn-main', 'btn-outline-dark')
  }
  enableOpener() {
    const btn = this.openerTarget
    btn.disabled = false
    btn.classList.replace('btn-outline-dark', 'btn-main')
  }
}
