import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'feedback', 'answer']

  select(){
    let selectedIndex = parseInt(event.target.dataset.index)
    this.answerTargets.forEach((el, i) => {
      el.classList.toggle('active', i === selectedIndex)
    })
    this.feedbackTarget.innerHTML = event.target.querySelector('.feedback').innerHTML
    this.feedbackTarget.classList.remove('d-none')

    const correct = event.target.dataset.correct
    this.element.dataset.answer = (correct === 'true' ? 'correct' : 'wrong')
    Rails.fire(this.element, 'traininganswered')
  }
}
