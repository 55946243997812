import { Controller } from "stimulus"

const gradeTable = {
  0: [83 , 50, 40, 30],
  1: [83 , 50, 40, 30],
  2: [83 , 50, 40, 30],
  3: [83 , 50, 40, 30],
  4: [83 , 50, 40, 30],
  5: [83 , 60, 50, 40],
  6: [83 , 68, 60, 50],
  7: [90 , 75, 68, 60],
  8: [90 , 83, 71, 64],
  9: [101, 90, 75, 68],
  10: [101, 90, 83, 71]
};

// level is first array index less than the given score
// that is why last
const writingLevels = [0, 20, 30, 40, 50, 60, 68, 75, 83, 90, 101]

export default class extends Controller {
  static targets = [ 'input', 'overall', 'grade', 'level', 'total' ]

  connect() {
    this.scores = gradeTable[parseInt(this.data.get('level'))];
  }

  calculate() {
    var total = 0;
    this.inputTargets.forEach((input) => {
      total += parseInt(input.value);
    });
    this.totalTarget.innerText = total;

    const percent = 4 * total;
    this.overallTarget.innerText = `${percent}%`;

    var grade = 'D';
    const index = this.scores.findIndex((s) => { return percent > s; });
    if (-1 < index) {
      grade = ['A+', 'A', 'B', 'C'][index];
    }
    this.gradeTarget.innerText = grade;

    const level = writingLevels.findIndex((s) => { return percent < s })
    this.levelTarget.innerText = level
  }
}
