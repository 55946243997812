import { Controller } from "stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [ 'note', 'tip', 'list', 'placeholder', 'objective', 'nextButton' ]

  initialize() {
    this.count = this.noteTargets.length
    this.objective = 1
    this.wrongAttempt = false
    let timer = 0

    this.sortable = new Sortable(this.listTarget, {
      animation: 150,
      ghostClass: 'plan-note-ghost',
      filter: '.plan-note-placed, .plan-note-placeholder',
      onChoose: (event) => {
        this.disableWrongIndicators()
      },
      onEnd: (event) => {
        clearTimeout(timer)
        timer = setTimeout(() => {this.check(event)}, 50)
      },
      onMove: (event) => {
        this.wrongAttempt = false
        const rel = event.related

        if (rel.classList.contains('plan-note-placed')) {
          return false // don't move correctly placed notes
        }

        if (rel.classList.contains('plan-note-placeholder')) {
          if (rel.dataset.order === event.dragged.dataset.order) {
            rel.style.visibility = 'hidden'
            return -1 // drop before the placeholder
          } else {
            this.wrongAttempt = true
            return false // cancel wrong drop
          }
        } else {
          this.placeholderTarget.style.visibility = 'visible'
        }
      }
    });
  }

  check(event) {
    const note = event.item
    const order = parseInt(note.dataset.order)

    if (order - 1 === event.newIndex && order === this.objective) {
      note.classList.add('plan-note-placed')
      note.classList.remove('plan-note-unplaced')
      this.objective += 1
      this.objectiveTarget.innerText = this.objective
      this.placeholderTarget.dataset.order = this.objective
    } else if (this.wrongAttempt) {
      this.indicateWrong(note)
    }

    if (this.objective > this.count) {
      this.planComleted()
    } else {
      this.placeholderTarget.style.visibility = 'visible'
    }
  }

  planComleted() {
    this.placeholderTarget.style.display = 'none'
    this.sortable.destroy()
    this.nextButtonTarget.classList.replace('btn-outline-secondary', 'btn-success')
    this.nextButtonTarget.removeAttribute('disabled')
    this.nextButtonTarget.classList.remove('disabled')
    this.tipTargets.forEach((tip) => {
      tip.classList.add('fade-in')
      tip.classList.remove('d-none')
    })
    this.noteTargets.forEach(note => {
      note.classList.add('plan-note-placed')
    })
  }

  indicateWrong(note) {
    const indicator = note.querySelector('.plan-wrong-indicator')
    indicator.classList.remove('fade-in-out')
    void indicator.offsetWidth
    indicator.classList.add('fade-in-out')
  }

  disableWrongIndicators() {
    this.element.querySelectorAll('.plan-wrong-indicator').forEach(i => {
      i.classList.remove('fade-in-out')
    })
  }
}
