import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import JQuery from 'jquery'

export default class extends Controller {
  static targets = [ 'skillsModal', 'skillsShow', 'skillsStart', 'skillsForm',
                     'editor', 'anwser', 'textarea', 'text']

  initialize() {
    JQuery(this.skillsModalTarget).on('hide.bs.modal', () => {
      Rails.fire(this.skillsFormTarget, 'submit');
      this.onSkillSaved();
    });

    this.updateDisplay();
  }

  get state(){
    return this.data.get('state')
  }

  set state(value){
    this.data.set('state', value);
    this.updateDisplay();
  }

  updateDisplay() {
    const state = this.state;

    this.skillsShowTarget.classList.toggle('d-none', state === 'skill' || state === 'lock');
    this.skillsStartTarget.classList.toggle('d-none', state !== 'skill');
    this.editorTarget.classList.toggle('d-none', state !== 'write');
    this.anwserTarget.classList.toggle('d-none', state !== 'review');
  }

  showSkills() {
    JQuery(this.skillsModalTarget).modal({ backdrop: 'static', keyboard: false, focus: true, show: true })
  }

  hideSkills() {
    JQuery(this.skillsModalTarget).modal('hide')
  }

  showEditor() {
    this.state = 'write';
  }

  onSkillSaved() {
    if (this.state === 'skill') {
      this.state = 'write';
    }
  }

  onTextSaved() {
    const html = this.textareaTarget.value.replace(/\n/g, '<br />');
    this.textTarget.innerHTML = html;
    this.state = 'review';
    Rails.fire(this.element, 'draftsaved');
  }
}
