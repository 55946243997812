import { Controller } from "stimulus"
import JQuery from 'jquery'

export default class extends Controller {
  static targets = [ 'lesson', 'content' ]

  initialize() {
    this.mql = window.matchMedia('screen and (min-width: 60em)')

    if (this.mql.matches) {
      this.showLesson()
    }

    this.mql.addListener((event) => {
      if (event.matches) {
        JQuery(`#lesson-modal-${this.lesson}`).modal('hide')
        this.showLesson()
      } else {
        this.lessonTargets.forEach(el => {
          el.classList.remove('lesson-card-selected')
        })
        this.contentTargets.forEach(el => {
          el.classList.add('d-none')
        })
      }
    })
  }

  tab(event){
    this.lesson = event.currentTarget.dataset.lesson
  }

  showLesson() {
    if (this.mql.matches) {
      this.lessonTargets.forEach(el => {
        el.classList.toggle('lesson-card-selected', el.dataset.lesson === this.lesson)
      })

      this.contentTargets.forEach(el => {
        el.classList.toggle('d-none', el.dataset.lesson !== this.lesson)
      })
    } else {
      JQuery(`#lesson-modal-${this.lesson}`).modal('show')
    }
  }

  get lesson(){
    return this.data.get('lesson')
  }

  set lesson(value){
    this.data.set('lesson', value)
    this.showLesson()
  }
}
