import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['image']

  connect() {
    this.element.querySelectorAll('input[type=password]').forEach(input => {
      input.addEventListener('focus', (event) => {event.target.removeAttribute('readonly')})
    })
  }

  previewAvatar(event) {
    const input = event.currentTarget
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = () => { this.imageTarget.src = reader.result }
      reader.readAsDataURL(input.files[0])
    }
  }
}
