import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['paginate', 'objective', 'bullet', 'prev', 'next']

  initialize(){
    this.bulletTargets.forEach((bullet, index) => {
      bullet.addEventListener('click', () => { this.index = index })
    });

    this.showContent()

    if (this.objectiveTargets.length > 3) {
      // feedback present
      this.index = this.objectiveTargets.length - 1;
    }
  }

  get index(){
    return parseInt(this.data.get('index') || 0)
  }

  set index(value){
    this.data.set('index', value)
    this.showContent()
  }

  next(){
    if (this.index < this.objectiveTargets.length - 1) this.index ++ ;
  }

  prev(){
    if (this.index > 0) this.index -- ;
  }

  showContent(){
    this.objectiveTargets.forEach((el, i) => {
      el.classList.toggle('d-none', i !== this.index)
    })
    this.paginateTarget.innerHTML = " (" + (this.index + 1) + "/" + this.objectiveTargets.length + ")"

    this.bulletTargets.forEach((el, i) => {
      if(this.index === i){
        el.querySelector('i').textContent = 'radio_button_checked'
      }
      else {
        el.querySelector('i').textContent = 'radio_button_unchecked'
      }
      el.classList.toggle('d-none', i >= this.objectiveTargets.length)
    })
    this.prevTarget.classList.toggle('fade', this.index === 0)
    this.nextTarget.classList.toggle('fade', this.index + 1 === this.objectiveTargets.length)
  }
}
