import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['counter', 'text', 'button']

  connect(){
    const delay = 300;
    let timer = 0;
    this.textTarget.addEventListener('keyup', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {this.update()}, delay);
    });
    this.update();
  }

  update(){
    let count = 0, text = this.textTarget.value;
    if (text && text.length > 0){
      count = text.match(/\S+/g).length
    }
    this.counterTarget.innerText = count;

    const disabled = count > 0 ? false : true;
    this.buttonTargets.forEach((button) => { button.disabled = disabled });
  }
}
