import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'body', 'comment', 'commentsContainer']

  initialize() {
    this.bodyTarget.querySelectorAll('[title]').forEach((element) => {
      var title = element.getAttribute('data-title');
      var content = element.getAttribute('title');

      if (content.length > 0) {
        element.removeAttribute('title');

        if (element.classList.contains('gms-comment')){
          title = '&#x1F4AC; Comment';
          this.addComment(content, element);
          this.commentsContainerTarget.classList.remove('d-none')
        } else if (element.classList.contains('gms-g')) {
          title = '&#x1F530;' + title;
        } else if (element.classList.contains('gms-w')) {
          title = '&#x1F4A1;' + title;
        }

        $(element).popover({
          container: '#corrected-text',
          title: title,
          html: true,
          content: content,
          trigger: 'hover focus',
          placement: 'top'
        });
      }
    });
  }
  addComment(content, element){
    const img = element.cloneNode(false)
    img.removeAttribute('class')
    img.removeAttribute('title')
    img.removeAttribute('alt')
    const span = document.createElement('span')
    span.textContent = content

    const entry = document.createElement('div')
    entry.classList.add('comment-entry')
    entry.appendChild(img)
    entry.appendChild(span)
    this.commentTarget.appendChild(entry)
  }
}
