import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ['modal']

  connect(){
    $(this.modalTarget).modal({show: false});
  }

  finishing(){
    $(this.modalTarget).modal('show');
  }

  sending(){
    $(this.modalTarget).modal('hide');
  }
}
