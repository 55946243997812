import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import JQuery from 'jquery'

export default class extends Controller {
  static targets = [
    'input', 'bulkCheckbox',
    'reassignButton', 'reassignGraders', 'reassignModal', 'reassignCount',
    'retrieveButton', 'deleteButton'
  ]

  initialize() {
    if (this.inputTargets.length > 0) {
      this.bulkCheckboxTarget.disabled = false
      this.reassignButtonTarget.classList.remove('d-none')
      this.retrieveButtonTarget.classList.remove('d-none')
      this.deleteButtonTarget.classList.remove('d-none')
    }
  }

  collectInputs() {
    let checked = false
    let groups = new Set
    let group = null
    this.inputTargets.forEach(input => {
      if (input.checked) {
        checked = true
        group = input.dataset.group
        groups.add(group)
      }
    })
    if (checked) {
      this.enable(this.retrieveButtonTarget)
      this.enable(this.deleteButtonTarget)
    } else {
      this.disable(this.retrieveButtonTarget)
      this.disable(this.deleteButtonTarget)
    }
    if (groups.size === 1) {
      this.enable(this.reassignButtonTarget)
      this.reassignButtonTarget.dataset.group = group
    } else {
      this.disable(this.reassignButtonTarget)
      delete this.reassignButtonTarget.dataset.group
    }
  }

  checkAll(event) {
    this.inputTargets.forEach(input => {
      input.checked = event.currentTarget.checked
    })
    this.collectInputs()
  }

  reassign() {
    const btn = this.reassignButtonTarget,
          urlTemplate = btn.dataset.url,
          groupID = btn.dataset.group,
          gradersURL = urlTemplate.replace(/\/[0-9]+\.json/, `/${groupID}.json`)

    this.reassignCountTarget.innerText = this.inputTargets.filter(input => input.checked).length

    Rails.ajax({
      url: gradersURL,
      type: 'GET',
      success: (data) => {
        this.reassignGradersTarget.innerHTML = ''
        data.forEach(grader => {
          const li = document.createElement('option')
          li.textContent = `${grader.name} / ${grader.email}`
          li.value = grader.id
          this.reassignGradersTarget.appendChild(li)
        })
        JQuery(this.reassignModalTarget).modal('show')
      }
    })
  }

  disable(btn) {
    btn.disabled = true
    btn.classList.replace('btn-main', 'btn-outline-dark')
  }

  enable(btn) {
    btn.disabled = false
    btn.classList.replace('btn-outline-dark', 'btn-main')
  }
}
