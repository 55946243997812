import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'task', 'nav' ]

  initialize() {
    if (this.navTargets.length > 0) {
      this.navTargets[0].click()
    }
  }

  showTask(event) {
    const detail = event.detail
    const data = detail[0]
    this.taskTarget.innerHTML = data
    const current = event.currentTarget
    this.navTargets.forEach(nav => {nav.classList.toggle('selected', nav === current)})
  }

  updateTask(event) {
    const detail = event.detail
    const data = detail[0]
    this.taskTarget.innerHTML = data
  }
}
