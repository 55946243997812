import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'input', 'save', 'finish', 'message']

  connect(){
    const delay = 300
    let timer = 0
    this.inputTarget.addEventListener('keyup', () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.messageTarget.classList.add('d-none')
      }, delay)
    })

    this.formTarget.addEventListener('ajax:send', (event) => {
      this.finishTarget.disabled = true
      this.saveTarget.disabled = true
      this.messageTarget.classList.add('d-none')
    })

    this.formTarget.addEventListener('ajax:complete', (event) => {
      this.finishTarget.disabled = false
      this.saveTarget.disabled = false
      if (this.saveTarget.tagName === 'BUTTON') {
        this.saveTarget.innerText = 'Save'
      } else {
        this.saveTarget.value = 'Save'
      }
    })

    this.formTarget.addEventListener('ajax:success', (event) => { this.ajaxSuccess(event) })
    this.formTarget.addEventListener('ajax:error', (event) => { this.ajaxError(event) })
  }

  save() {
    if (this.saveTarget.tagName === 'BUTTON') {
      this.saveTarget.innerText = 'Saving...'
    } else {
      this.saveTarget.value = 'Saving...'
    }
  }

  ajaxSuccess(event) {
    this.showMessage(event.detail[0], '&#10003; Saved')
  }

  ajaxError(event) {
    this.showMessage(event.detail[0], '&#10007; Error!')
  }

  showMessage(msg, defaultMsg) {
    if (msg.length && !this.isJavascript(msg)) {
      this.messageTarget.innerHTML = msg
    } else {
      this.messageTarget.innerHTML = defaultMsg
    }
    this.messageTarget.classList.remove('d-none')
  }

  isJavascript(response) {
    return response.includes('Turbolinks')
  }
}
