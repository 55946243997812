import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'section', 'finalize']

  initialize(){
    const originalStates = [];
    let lock = false;
    this.sectionTargets.forEach((section, index) => {
      if (section.dataset.draftSectionState !== 'review') {
        if (lock) {
          originalStates[index] = section.dataset.draftSectionState;
          section.dataset.draftSectionState = 'lock';
        } else {
          lock = true;
        }
      }

      section.addEventListener('draftsaved', () => {
        const nextSection = this.sectionTargets[index + 1];
        const originalState = originalStates[index + 1];
        if (nextSection &&
            originalState &&
            nextSection.dataset.draftSectionState === 'lock') {
          nextSection.dataset.draftSectionState = originalState;
          this.application.getControllerForElementAndIdentifier(nextSection, 'draft-section').updateDisplay()
        }

        this.toggleFinalizeButton()
      });
    });
    this.toggleFinalizeButton()
  }

  toggleFinalizeButton() {
    const incompleteSection = this.sectionTargets.find((section) => { return section.dataset.draftSectionState !== 'review' });
    if (incompleteSection) {
      window.scroll({top: document.body.scrollHeight, behavior: 'smooth'})
      setTimeout(() => {
        // smooth scroll didn't work
        if (this.finalizeTarget.getBoundingClientRect().bottom > window.innerHeight) {
          window.scrollTo(0, document.body.scrollHeight)
        }
      }, 200)

      this.finalizeTarget.classList.replace('btn-success', 'btn-outline-secondary')
      this.finalizeTarget.classList.add('disabled');
      this.finalizeTarget.setAttribute('disabled', 'disabled');
    } else {
      this.finalizeTarget.classList.replace('btn-outline-secondary', 'btn-success')
      this.finalizeTarget.classList.remove('disabled');
      this.finalizeTarget.removeAttribute('disabled');
    }
  }

}
