import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'tip', 'tipBtn', 'skillsModal', 'skillsForm', 'finishButton', 'writing' ]

  initialize(){
    this.showRandomTip();

    if (this.data.get('skills') === 'pending') {
      this.writingTargets.forEach((element) => {
        element.classList.add('d-none');
      });

      $(this.skillsModalTarget).on('hide.bs.modal', () => {
        Rails.fire(this.skillsFormTarget, 'submit');
        this.writingTargets.forEach((element) => {
          element.classList.remove('d-none');
        });
      });

      this.showSkills();
    }
  }

  showRandomTip(){
    const random = Math.floor(Math.random() * this.tipTargets.length);
    this.tipTargets.forEach((tip, index) => {
      tip.classList.toggle('d-none', index !== random);
    });
  }

  showAllChecklist(){
    this.tipTargets.forEach((tip) => {
      tip.classList.remove('d-none')
    });
    this.tipBtnTarget.classList.add('d-none');
  }

  showSkills() {
    $(this.skillsModalTarget).modal({ backdrop: 'static', keyboard: false });
  }

  onSaved(){
    window.scroll({top: document.body.scrollHeight, behavior: 'smooth'})
    setTimeout(() => {
      // smooth scroll didn't work
      const buttonBottom = this.finishButtonTarget.getBoundingClientRect().bottom
      if (buttonBottom > window.innerHeight) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    }, 200)

    this.finishButtonTarget.classList.replace('btn-outline-secondary', 'btn-success')
    this.finishButtonTarget.classList.remove('disabled');
    this.finishButtonTarget.removeAttribute('disabled');
  }
}
