import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce';

export default class extends Controller {
  connect(){
    const textarea = this.element
    tinymce.init({
      target: this.element,
      hidden_input: false,
      skin_url: false,
      skin: false,
      content_css: this.data.get('css'),
      plugins: 'lists paste',
      toolbar: 'bold italic forecolor | bullist numlist | fontselect | removeformat | undo redo',
      menubar: false,
      statusbar: false,
      branding: false,
      browser_spellcheck: true,
      paste_as_text: true,
      draggable_modal: true,
      forced_root_block: false,
      setup: function(editor) {
        editor.on('change', () => { textarea.dispatchEvent(new Event('change')) })
      }
    });
  }
}
