// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

require('../lib/polyfills')
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require("tinymce")
require("tinymce/plugins/wordcount")
require("tinymce/plugins/lists")
require("tinymce/plugins/paste")
require('tinymce/themes/silver')
require('tinymce/icons/default')

import 'bootstrap/dist/js/bootstrap'
import "controllers"

// Images
require.context('../images', true)

// CSS
import 'stylesheets'
