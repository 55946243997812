import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['token', 'feedbackCorrect', 'feedbackWrong', 'selectionHint' ]

  initialize() {
    this.element.dataset.highlightNumber = this.tokenTargets.filter((token) => {
      return token.dataset.correct === 'correct'
    }).length
  }

  select(){
    // there are <u> tags not part of the highlighter
    if (event.target.nodeName === 'U') return;

    event.target.classList.toggle('active')

    if (this.isCompleted) {
      let iscorrect = this.isCorrect
      this.toggleFeedback(iscorrect)
      this.element.dataset.answer = (iscorrect ? 'correct' : 'wrong')
      Rails.fire(this.element, 'traininganswered')
    } else {
      this.showHints()
    }
  }

  toggleFeedback(correct){
    this.tokenTargets.forEach(token => {
      if (token.classList.contains('active')) {
        token.classList.add(token.dataset.correct)
      }
    })
    this.selectionHintTarget.classList.add('d-none')
    this.feedbackCorrectTarget.classList.toggle('d-none', !correct)
    this.feedbackWrongTarget.classList.toggle('d-none', correct)
  }

  showHints() {
    let left = this.number - this.selectedTokens.length
    if (left > 0) {
      this.selectionHintTarget.innerText = `Select ${left} more`
      this.feedbackCorrectTarget.classList.add('d-none')
      this.feedbackWrongTarget.classList.add('d-none')
      this.selectionHintTarget.classList.remove('d-none')
    }
  }

  get isCorrect() {
    return (undefined === this.wrongSelection)
  }

  get wrongSelection () {
    return this.tokenTargets.find((token) => {
      return (token.classList.contains('active') ? token.dataset.correct === 'wrong' : token.dataset.correct === 'correct')
    })
  }

  get isCompleted() {
    return this.number <= this.selectedTokens.length
  }

  get number() {
    return parseInt(this.element.dataset.highlightNumber)
  }

  get selectedTokens() {
    return this.tokenTargets.filter((token) => {
      return token.classList.contains('active')
    })
  }
}
