import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['indicator',
                    'background', 'backgroundArrow',
                    'situation', 'situationArrow',
                    'objective', 'objectiveArrow']

  connect(){
    const delay = 100
    let timer = 0

    window.addEventListener('scroll', () => {
      clearTimeout(timer)
      timer = setTimeout(() => {this.toggleIndicator()}, delay)
    })

    this.toggleIndicator()
  }

  toggleIndicator() {
    const h = document.documentElement.clientHeight

    if (this.backgroundCheck(h) || this.situationCheck(h) || this.objectiveCheck(h)) {
      this.indicatorTarget.classList.remove('d-none')
    } else {
      this.indicatorTarget.classList.add('d-none')
    }
  }

  objectiveCheck(clientHeight) {
    const arrow = this.objectiveArrowTarget.getBoundingClientRect()
    const content = this.objectiveTarget.getBoundingClientRect()
    if (arrow.bottom < clientHeight) this.objectiveArrowTarget.classList.remove('o-0')
    return arrow.bottom > clientHeight && content.bottom < clientHeight
  }

  situationCheck(clientHeight) {
    const arrow = this.situationArrowTarget.getBoundingClientRect()
    const content = this.situationTarget.getBoundingClientRect()
    if (arrow.bottom < clientHeight) this.situationArrowTarget.classList.remove('o-0')
    return arrow.bottom > clientHeight && content.bottom < clientHeight
  }

  backgroundCheck(clientHeight) {
    const arrow = this.backgroundArrowTarget.getBoundingClientRect()
    const content = this.backgroundTarget.getBoundingClientRect()
    if (arrow.bottom < clientHeight) this.backgroundArrowTarget.classList.remove('o-0')
    return arrow.bottom > clientHeight && content.bottom < clientHeight
  }
}
