import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'password', 'toggle' ]

  togglePasswordVisibility(event) {
    event.preventDefault()

    if (event.currentTarget.textContent === 'visibility') {
      event.currentTarget.textContent = 'visibility_off'
      this.makeTextInput(this.passwordTarget)
    } else {
      event.currentTarget.textContent = 'visibility'
      this.makePasswordInput(this.passwordTarget)
    }
  }

  makeTextInput(input) {
    input.type = 'text'
    input.setAttribute('autocomplete', 'off')
    input.setAttribute('autocorrect', 'off')
    input.setAttribute('autocapitalize', 'off')
    input.setAttribute('spellcheck', 'false')
  }

  makePasswordInput(input) {
    input.removeAttribute('autocomplete')
    input.removeAttribute('autocorrect')
    input.removeAttribute('autocapitalize')
    input.removeAttribute('spellcheck')
    input.type = 'password'
  }
}
