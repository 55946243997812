import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form' ]

  connect() {
    const mql = window.matchMedia('(orientation: landscape)')

    if (mql.matches) {
      this.dismiss()
    }

    mql.addListener((event) => {
      if (event.matches) {
        this.dismiss()
      }
    })
  }

  dismiss() {
    this.element.classList.add('d-none')
    Rails.fire(this.formTarget, 'submit')
  }
}
