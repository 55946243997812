import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce';

export default class extends Controller {
  initialize() {
    const controller = this

    tinymce.PluginManager.add('gms', function(editor) {

      function registerButton(name, abbr, tooltip, explanation) {
        editor.ui.registry.addButton(name, {
          text: abbr,
          tooltip: tooltip,
          onAction: function () {
            const selection = editor.selection;
            const selectedContent = selection.getContent();
            const mark = controller.makeMark(abbr, explanation, tooltip)
            if (selectedContent.length) {
              editor.insertContent(mark);
              editor.insertContent(' ' + selectedContent);
            } else {
              editor.insertContent(mark);
              editor.insertContent(' ');
            }
          }
        });
      }

      registerButton('gmsggen', 'G', 'Grammar', 'The grammar is incorrect.');
      registerButton('gmsgvt', 'Gvt', 'Grammar (verb tenses)', 'The verb tense is not correct.');
      registerButton('gmsga', 'Gart', 'Grammar (articles)', 'The  article (a/an, the, or no article) is not correct.');
      registerButton('gmsgprep', 'GPrep', 'Grammar (prepositions)', 'The  preposition (at,in,on, etc.) is wrong.');
      registerButton('gmsgsp', 'Gsing', 'Grammar (singular/plurals)', 'There is a problem here with singular and plural noun/verb agreement. Make sure the noun and verb agree.');
      registerButton('gmsgconj', 'Gconj', 'Grammar (conjunctions)', 'The linking word (and, but, however, etc.) is incorrect.', 'gms-g gms-g-conj');
      registerButton('gmsgcount', 'Gcu', 'Grammar (countable/uncountable)','There is a problem here because the noun is either countable or uncountable.');
      // Structural
      registerButton('gmsopara', '//', 'Paragraphing', 'The ideas are not clearly organized into paragraphs, with one main idea in each paragraph.');
      registerButton('gmsounclear', '?', 'Unclear', "I don't understand what you want to say here.");
      registerButton('gmsovocab', 'V', 'Vocabulary', 'This is the wrong word.');
      registerButton('gmsonn', 'NE', 'Not natural English', 'This is correct but it does not sound very natural.');
      registerButton('gmsowo', 'WO', 'Word order', 'The words are in the wrong order.', 'gms-w gms-w-wordorder');
      registerButton('gmsost', 'ST', 'Style/Tone', 'This is not the right style for the person you are writing to.');
      registerButton('gmsowf', 'WF', 'Wrong form', 'You have used the wrong form of the word.');
      registerButton('gmsoconc', 'C', 'Concision', "You can write this in a shorter, clearer way. You may have repeated yourself,  or used an expression that you don't need.");
      registerButton('gmsopunc', 'P', 'Punctuation', "The punctuation (Aa , . ; : ? ! “” ') is not correct.");
      registerButton('gmsospell', 'Sp', 'Spelling', 'The spelling is incorrect.');
      registerButton('gmsocont',  'Ct', 'Content', 'There is either some unnecessary information or some missing information that is important for the task.');
      registerButton('gmsoorg',  'Org', 'Organization', 'These ideas are not organized logically.');
      // Comment
      editor.ui.registry.addButton('gmscomment', {
        icon: 'comment',
        tooltip: 'Insert/Edit comment',
        onAction: function () { controller.showCommentDialog(editor) }
      });

      return {
        getMetadata: function () {
          return  { name: "GMS", url: "http://reallyenglish.com" }
        }
      }
    })
  }

  makeComment(number, comment) {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><circle cx="9" cy="9" r="9" fill="#9652a0" /><text x="9" y="13" text-anchor="middle" fill="white" font-size="12" font-family="Arial">${number}</text></svg>`
    const img = document.createElement('img')
    img.setAttribute('class', 'gms-comment')
    img.setAttribute('title', comment)
    img.setAttribute('alt', number)
    img.src = `data:image/svg+xml;base64,${btoa(svg)}`
    return img.outerHTML
  }

  makeMark(abbr, title, tooltip) {
    const width = 10 + (abbr.length * 7)
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" height="17" width="${width + 2}">`+
          `<rect x="1" y="1" height="15" width="${width}" stroke="#357EDD" rx="5" stroke-width="1" fill="#357EDD" />` +
          `<text x="${1+(width/2)}" y="13" text-anchor="middle" font-family="Verdana" font-size="12" fill="#fefefe">${abbr}</text></svg>`
    return `<img class="gms-mark" title="${title}" data-title="${tooltip}" alt="${abbr}" src="data:image/svg+xml;base64,${btoa(svg)}">`
  }

  showCommentDialog(editor, selectedNode) {
    const controller = this
    const textarea = this.element

    var title = ''
    var node = selectedNode || editor.selection.getNode()
    if (node && node.classList.contains('gms-comment')) {
      title = node.getAttribute('title')
    } else {
      node = null
    }

    return editor.windowManager.open({
      title: 'Input a Comment',
      initialData: { comment: title },
      body: { type: 'panel', items: [ { type: 'textarea', name: 'comment' } ] },
      buttons: [
        { type: 'cancel', text: 'Cancel' },
        { type: 'submit', text: 'Insert', primary: true }
      ],
      onSubmit: function (api) {
        var data = api.getData();
        if (data.comment && data.comment.length > 0) {
          if (node) {
            node.setAttribute('title', data.comment)
          } else {
            editor.insertContent(controller.makeComment('...', data.comment))
          }
          textarea.dispatchEvent(new Event('change'))
        }
        api.close();
      }
    });
  }

  redrawComments(root) {
    const self = this
    root.querySelectorAll('.gms-comment').forEach(function(img, index) {
      img.outerHTML = self.makeComment(index + 1, img.getAttribute('title'))
    })
  }

  connect(){
    const textarea = this.element
    const controller = this

    tinymce.init({
      target: this.element,
      hidden_input: false,
      skin_url: false,
      skin: false,
      content_css: this.data.get('css'),
      plugins: 'gms paste',
      toolbar: [
        'gmscomment | bold italic strikethrough underline forecolor | fontselect | removeformat | undo redo',
        'gmsopara gmsounclear gmsovocab gmsonn gmsowo gmsost gmsowf gmsoconc gmsopunc gmsospell gmsocont gmsoorg | gmsggen gmsgvt gmsga gmsgprep gmsgsp gmsgconj gmsgcount'
      ],
      menubar: false,
      statusbar: false,
      branding: false,
      browser_spellcheck: true,
      paste_as_text: true,
      draggable_modal: true,
      forced_root_block: false,
      setup: function(editor) {
        let timer = 0
        const update = function() {
          controller.redrawComments(editor.dom.getRoot())
          textarea.dispatchEvent(new Event('change'))
        }

        editor.on('change', () => {
          clearTimeout(timer)
          timer = setTimeout(update, 100)
        })

        editor.on('click', (e) => {
          if (e.target.classList.contains('gms-comment')) {
            controller.showCommentDialog(editor, e.target)
          }
        })
      }
    })
  }
}
