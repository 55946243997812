import { Controller } from "stimulus"
import JQuery from 'jquery'

export default class extends Controller {
  static targets = ['task', 'answer', 'field', 'input', 'feedback', 'button', 'refresh', 'continue']

  initialize(){
    this.timer = 0
    this.phrases = this.taskTarget.querySelectorAll('span.target')
    this.start()
  }

  triggerCheck(){
    const delay = 200;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {this.maybeEnableButton()}, delay);
  }

  maybeEnableButton(){
    const unanswered = this.inputTargets.find((input) => {
      return input.value.length === 0
    })
    if (undefined === unanswered){
      this.buttonTarget.classList.remove('d-none')
    }
  }

  focus(){
    const inputIndex = parseInt(event.target.dataset.index)
    this.phrases.forEach((phrase, phraseIndex) => {
      phrase.classList.toggle('focused', phraseIndex === inputIndex)
    })
  }

  check(){
    this.taskTarget.classList.add('d-none')
    this.answerTarget.classList.remove('d-none')
    this.buttonTarget.classList.add('d-none')
    this.refreshTarget.classList.remove('d-none')

    this.enableContinueButton()

    this.fieldTargets.forEach((el, i) => {
      const entered = el.querySelector('input').value.trim()
      const feedback = this.feedbackTargets[i]

      var answers = feedback.dataset.answers
      if (typeof answers === "string") {
        answers = JSON.parse(answers)
      }
      const correct = answers.includes(entered)

      el.classList.add('d-none')
      feedback.classList.remove('d-none');
      feedback.querySelector('dt').innerHTML = entered
      feedback.querySelector('span.text-success').classList.toggle('d-none', !correct)
      feedback.querySelector('span.text-danger').classList.toggle('d-none', correct)
    })
  }

  start(){
    this.taskTarget.classList.remove('d-none')
    this.answerTarget.classList.add('d-none')
    this.buttonTarget.classList.add('d-none')
    this.refreshTarget.classList.add('d-none')

    this.disableContinueButton()

    this.phrases.forEach(el => { el.classList.remove('focused') })
    this.feedbackTargets.forEach(el => { el.classList.add('d-none') })
    this.fieldTargets.forEach(el => {
      el.classList.remove('d-none')
      el.querySelector('input').value = ''
    })
  }

  disableContinueButton() {
    this.continueTarget.setAttribute('disabled', 'disabled')
    this.continueTarget.classList.replace('btn-success', 'btn-outline-secondary')
  }
  enableContinueButton() {
    this.continueTarget.removeAttribute('disabled')
    this.continueTarget.classList.replace('btn-outline-secondary', 'btn-success')
  }

  close() {
    JQuery(this.element.closest('div.modal[role="dialog"]')).modal('hide')
  }
}
