import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'icon', 'input' ]

  initialize() {
    this.element.addEventListener('ajax:send', () => {
      this.iconTarget.classList.remove('invisible')
      this.iconTarget.innerText = 'hourglass_empty'
      this.iconTarget.classList.remove('text-success')
      this.iconTarget.classList.add('text-secondary')
      this.inputTarget.disabled = true
    })
    this.element.addEventListener('ajax:complete', () => {
      this.inputTarget.disabled = false
    })
    this.element.addEventListener('ajax:success', () => {
      this.iconTarget.innerText = 'done'
      this.iconTarget.classList.remove('text-secondary')
      this.iconTarget.classList.add('text-success')
    })
    this.element.addEventListener('ajax:error', () => {
      this.iconTarget.innerText = 'error'
      this.iconTarget.classList.add('text-secondary')
      this.iconTarget.classList.remove('text-success')
    })
  }

  update() {
    this.element.submit()
  }
}
