import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'button' ]

  connect() {
    const delay = 2000;
    let timer = 0;

    this.element.querySelectorAll('textarea, input').forEach(el => {
      el.addEventListener('change', () => {
        this.buttonTarget.value = 'Save'
        clearTimeout(timer)
        timer = setTimeout(() => {this.save()}, delay)
      })
    })

    this.element.addEventListener('ajax:before', () => {
      this.buttonTarget.value = 'Saving...'
      this.buttonTarget.disabled = true
    })

    this.element.addEventListener('ajax:success', () => {
      this.buttonTarget.value = 'Saved'
    })

    this.element.addEventListener('ajax:error', () => {
      this.buttonTarget.value = 'Not Saved!'
    })

    this.element.addEventListener('ajax:complete', () => {
      this.buttonTarget.disabled = false
    })
  }

  save() {
    Rails.fire(this.element, 'submit')
  }
}
