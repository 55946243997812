import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input', 'output', 'description', 'datalist' ]

  initialize() {
    this.update()
  }

  update() {
    this.outputTarget.innerText = this.inputTarget.value
    const option = this.datalistTarget.children[this.inputTarget.value]
    if (option) {
      this.descriptionTarget.innerText = option.value
    }
  }
}
