import { Controller } from "stimulus"
import JQuery from 'jquery'

export default class extends Controller {
  static targets = ['tab', 'content', 'prev', 'next', 'continue']

  initialize(){
    this.showContent()

    this.finished = false

    const questionsCount = this.contentTargets.length
    const answers = []
    let answersCount = 0

    this.contentTargets.forEach((content, index) => {
      content.addEventListener('traininganswered', () => {
        if (answers[index] === undefined) {
          const answer = content.dataset.answer
          answers[index] = answer
          answersCount ++;
          this.updateAnswer(index, answer)
          if (answersCount == questionsCount) {
            this.finished = true
            this.toggleContinue()
          }
        }
      });
    })
  }

  updateAnswer(index, answer) {
    this.tabTargets[index].classList.add(`badge-answer-${answer}`)
  }

  next(){
    this.index ++
  }

  prev(){
    this.index --
  }

  get index(){
    return parseInt(this.data.get('index') || 0)
  }

  set index(value){
    this.data.set('index', value)
    this.showContent()
  }

  get atFirst() {
    return this.index === 0
  }
  get atLast() {
    return (this.index + 1) === this.tabTargets.length
  }

  showContent(){
    this.contentTargets.forEach((el, i) => {
      el.classList.toggle('d-none', i !== this.index)
    })
    this.tabTargets.forEach((el, i) => {
      el.classList.toggle('border-primary', i === this.index)
    })

    this.toggleNav(this.nextTarget, this.atLast);
    this.toggleNav(this.prevTarget, this.atFirst);
    this.toggleContinue()
  }

  toggleNav(button, disable){
    if (disable) {
      button.setAttribute('disabled', 'disabled')
    } else {
      button.removeAttribute('disabled', 'disabled')
    }
  }

  toggleContinue() {
    if (this.finished && this.atLast) {
      this.nextTarget.classList.add('d-none')
      this.continueTarget.classList.remove('d-none')
    } else {
      this.continueTarget.classList.add('d-none')
      this.nextTarget.classList.remove('d-none')
    }
  }
}
